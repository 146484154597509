<template>
  <v-menu>
    <template #activator="{ on: onMenu }">
      <v-tooltip left>
        <template #activator="{ on: onTooltip }">
          <v-icon v-on="{ ...onMenu, ...onTooltip }">mdi-file-download-outline</v-icon>
        </template>
        <span>Export</span>
      </v-tooltip>
    </template>
    <v-list>
      <v-list-item >
        <v-list-item-title @click="csvExport(csvData)">CSV</v-list-item-title>
      </v-list-item>
      <v-list-item >
        <v-list-item-title @click="excelExport(excelData)">Excel</v-list-item-title>
      </v-list-item>
      <v-list-item >
        <v-list-item-title v-clipboard:copy="clipboardData" v-clipboard:success="copyToClipboard" v-clipboard:error="onErrorCopyToClipboard">Clipboard</v-list-item-title>
      </v-list-item>
      <v-list-item v-if="showESNsToClipboard">
        <v-list-item-title v-clipboard:copy="clipboardDataOnlyESN" v-clipboard:success="copyToClipboard" v-clipboard:error="onErrorCopyToClipboard">ESNs to Clipboard</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import XLSX from 'xlsx';
export default {
  data: () => ({

  }),
  props: {
    csvData: Array,
    excelData: Array,
    clipboardData: String,
    sessionInfo: Object,
    emptyItems: String,
    clipboardDataOnlyESN: String,
    showESNsToClipboard: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    filename() {
      let timestamp = Math.floor(+new Date / 1000)
      return this.emptyItems.toLowerCase() +"_" + this.sessionInfo.username + "_" + this.sessionInfo.org_abbr + "_" + timestamp
    },
  },
  methods: {
    csvExport(csvData) {
      if (csvData ===  null || csvData === undefined || csvData.length == 0) {
        this.snackbartext = "No " + this.emptyItems.toLowerCase() + "."
        this.snackbar = true
      } else {
        let csvContent = "data:text/csv;charset=utf-8,";
        csvContent += [
          Object.keys(csvData[0]).join(","),
          ...csvData.map(item => Object.values(item).join(","))
        ]
          .join("\n")
          .replace(/(^\[)|(\]$)/gm, "");

        const data = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", data);
        link.setAttribute("download", this.filename + ".csv");
        link.click();
      }
    },
    excelExport(excelData) {
        if (excelData ===  null || excelData === undefined || excelData.length == 0) {
          this.snackbartext = "No " + this.emptyItems.toLowerCase() + "."
          this.snackbar = true
        } else {
          const data = XLSX.utils.json_to_sheet(excelData)
          const wb = XLSX.utils.book_new()
          XLSX.utils.book_append_sheet(wb, data, 'data')
          XLSX.writeFile(wb,this.filename + ".xlsx" )
        }
      },
      onErrorCopyToClipboard() {
        this.snackbartext = this.emptyItems + " failed to copy to clipboard."
        this.snackbar = true
      },
      copyToClipboard() {
        this.snackbartext = this.emptyItems + " copied to clipboard."
        this.snackbar = true
      },
  }
}
</script>


