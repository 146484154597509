<template>
  <div>
    <div v-if="label == 'Start'">
      <v-text-field color="#00677A" dense v-model="selectedDate" label="Start " append-icon="mdi-calendar"
        @click:append="showDialog" @click="showDialog" readonly></v-text-field>
    </div>
    <div v-if="label == 'End'">
      <v-text-field color="#00677A" dense v-model="selectedDate" label="End " append-icon="mdi-calendar"
        @click:append="showDialog" @click="showDialog" readonly></v-text-field>
    </div>
    <v-dialog v-model="dialogPickers" max-width="650px" persistent>
      <v-card flat style="padding-top: 25px">
        <v-card-text>
          <v-row justify="space-around">
            <v-date-picker color="#00677A" v-model="date"></v-date-picker>
            <v-time-picker color="#00677A" v-model="time" format="24hr"></v-time-picker>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn class="mr-2" @click.native="closeAndUpdate()">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>

export default {
  data: () => ({
    dialogPickers: false,
  }),
  props: {
    date: String,
    time: String,
    label: String,
    zone:String
  },
  computed: {
    selectedDate() {
      let zone = this.zone ? this.zone : " GMT"
      return this.date + " , " + this.time + " "+zone
    }
  },
  methods: {
    showDialog() {
      this.dialogPickers = true
    },
    closeAndUpdate() {
      this.dialogPickers = false
      let dateTime = this.date + "T" + this.time;
      this.$emit('update-dates-with-time', dateTime)
    }
  }
}
</script>


