<template>
  <div class="timezones">
    <v-card>
      <v-card-title>
        <v-text-field
          color="#00677A"
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="tz"
        :items-per-page="15"
        item-key="id"
        :selectable-key="id"
        dense
        show-select
        single-select
        v-model="selectedTimeZone"
        :search="search"
        v-on:item-selected="onSelected"
      ></v-data-table>
    </v-card>
  </div>
</template>

<script>
  export default {
  data: () => ({
    search: null,
    tz_countries: [],
    tx_continents: [],
    timezoneCountry: null,
    timezoneContinent: null,
    headers: [
        {text: "Id", value: "id"},
        {text: "Abbreviation", value: "abbr"},
        {text: "Offset", value: "offset"},
    //    {text: "Country", value: "country"},
    //    {text: "Continent", value: "continent"},
    ],
    selectedTimezone: [],
  }),
  props: {
    timezones: [],
    timezone: String,
  },

  mounted () {  
    if( this.selectedTimeZone != null && this.timezones != null) {
      this.selectedTimeZone.push(this.timezones.find(x => x.id === this.timezone))
      console.log("selectedTimeZone",this.selectedTimeZone)
    }
  },
  methods: {
    onSelected() {
        this.$nextTick(() => {
          //console.log(this.selectedTimeZone);
          //console.log(this.selectedTimeZone[0].id);
          this.timezone = this.selectedTimeZone.id
          this.$emit('selectedTimeZoneId',this.selectedTimeZone[0].id)
        });
    }
  },
  computed: {
    tz() {
      let t = []
      for(var i=0; i < this.timezones.length; i++) {
        let desc = this.timezones[i].id + " " + this.timezones[i].abbr + "  ("  + this.timezones[i].offset + ")"
        t.push({id: this.timezones[i].id, abbr: this.timezones[i].abbr, offset: this.timezones[i].offset, country: this.timezones[i].country, continent: this.timezones[i].continent,   name: desc})
      }
      return t
    },
    //TODO convert computed into watchers to resolve linter errors
    countries() {
      for(var i=0; i < this.timezones.length; i++) {
        if(this.tz_countries.indexOf(this.timezones[i].country) === -1) {
          // eslint-disable-next-line 
          this.tz_countries.push(this.timezones[i].country)
        }
      }
      return true
    },
    continents() {
      for(var i=0; i < this.timezones.length; i++) {
        if(this.tx_continents.indexOf(this.timezones[i].country) === -1) {
          // eslint-disable-next-line 
          this.tx_continents.push(this.timezones[i].country)
        }
      }
      return true
    }
  },
}
</script>

<style>

</style>