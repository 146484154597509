import colorPalette from "@/constants/colorPalette";

const {
  COLOR_LANDSCAPE,
  COLOR_BORDERS,
  COLOR_WATER,
  COLOR_LINE,
  COLOR_POINT_FILL,
  COLOR_SELECTED_POINT
} = colorPalette;

const COLORS = {
  BORDERS: COLOR_BORDERS,
  LANDSCAPE: COLOR_LANDSCAPE,
  LINE: COLOR_LINE,
  POINT: COLOR_SELECTED_POINT,
  POINT_FILL: COLOR_POINT_FILL,
  WATER: COLOR_WATER
};

const POINT_MARKER_ICON_CONFIG = {
  path: "M 0, 0 m -5, 0 a 5,5 0 1,0 10,0 a 5,5 0 1,0 -10,0",
  strokeOpacity: 0.7,
  strokeWeight: 4,
  // strokeColor: COLORS.POINT,
  // fillColor: COLORS.POINT_FILL,
  fillOpacity: 0.7,
  scale: 1
};

//const POINT_MARKER_CIRCLE_ICON_CONFIG = {
//    path: "M 0, 0 m -5, 0 a 5,5 0 1,0 10,0 a 5,5 0 1,0 -10,0",
//    strokeOpacity: 0.7,
//    strokeWeight: 4,
    // strokeColor: COLORS.POINT,
    // fillColor: COLORS.POINT_FILL,
//    fillOpacity: 0.7,
//    scale: 1
//  };

const POINT_MARKER_HEX_ICON_CONFIG = {
    path: "m -5, 0 l 2.5,-5 l 5,0 l 2.5,5 l -2.5,5 l -5,0 l -2.5,-5",
    strokeOpacity: 0.7,
    strokeWeight: 4,
    // strokeColor: COLORS.POINT,
    // fillColor: COLORS.POINT_FILL,
    fillOpacity: 0.7,
    scale: 1
};

const POINT_MARKER_TRIANGLE_ICON_CONFIG = {
  path: "m 0,5 l 5, -10 l -10, 0 l 5 , 10",
  strokeOpacity: 0.7,
  strokeWeight: 4,
  // strokeColor: COLORS.POINT,
  // fillColor: COLORS.POINT_FILL,
  fillOpacity: 0.7,
  scale: 1
};

const POINT_MARKER_SQUARE_ICON_CONFIG = {
  path: "m -5,5 l 10,0 l 0,-10 l -10,0 l 0,10",
  strokeOpacity: 0.7,
  strokeWeight: 4,
  // strokeColor: COLORS.POINT,
  // fillColor: COLORS.POINT_FILL,
  fillOpacity: 0.7,
  scale: 1
};

const POINT_MARKER_STAR_ICON_CONFIG = {
  path: "M12,15.39L8.24,17.66L9.23,13.38L5.91,10.5L10.29,10.13L12,6.09L13.71,10.13L18.09,10.5L14.77,13.38L15.76,17.66M22,9.24L14.81,8.63L12,2L9.19,8.63L2,9.24L7.45,13.97L5.82,21L12,17.27L18.18,21L16.54,13.97L22,9.24Z",
  strokeOpacity: 0.7,
  strokeWeight: 4,
  // strokeColor: COLORS.POINT,
  // fillColor: COLORS.POINT_FILL,
  fillOpacity: 0.7,
  scale: 1
};

const POINT_MARKER_TRUCK_CONFIG = {
  path: "M18,18.5A1.5,1.5 0 0,1 16.5,17A1.5,1.5 0 0,1 18,15.5A1.5,1.5 0 0,1 19.5,17A1.5,1.5 0 0,1 18,18.5M19.5,9.5L21.46,12H17V9.5M6,18.5A1.5,1.5 0 0,1 4.5,17A1.5,1.5 0 0,1 6,15.5A1.5,1.5 0 0,1 7.5,17A1.5,1.5 0 0,1 6,18.5M20,8H17V4H3C1.89,4 1,4.89 1,6V17H3A3,3 0 0,0 6,20A3,3 0 0,0 9,17H15A3,3 0 0,0 18,20A3,3 0 0,0 21,17H23V12L20,8Z",
  strokeOpacity: 1,
  strokeWeight: 0,
  fillOpacity: 1,
  scale: 1
};

const POINT_MARKER_TRAILER_CONFIG = {
  path: "M22,15V17H10A3,3 0 0,1 7,20A3,3 0 0,1 4,17H2V6A2,2 0 0,1 4,4H17A2,2 0 0,1 19,6V15H22M7,16A1,1 0 0,0 6,17A1,1 0 0,0 7,18A1,1 0 0,0 8,17A1,1 0 0,0 7,16Z",
  strokeOpacity: 1,
  strokeWeight: 0,
  fillOpacity: 1,
  scale: 1
};

const POINT_MARKER_FLASH_CONFIG = {
  path: "M11 15H6L13 1V9H18L11 23V15Z",
  strokeOpacity: 1,
  strokeWeight: 0,
  fillOpacity: 1,
  scale: 1
};

const POINT_MARKER_BARREL_CONFIG = {
  path: "M20 13C20.55 13 21 12.55 21 12S20.55 11 20 11H19V5H20C20.55 5 21 4.55 21 4S20.55 3 20 3H4C3.45 3 3 3.45 3 4S3.45 5 4 5H5V11H4C3.45 11 3 11.45 3 12S3.45 13 4 13H5V19H4C3.45 19 3 19.45 3 20S3.45 21 4 21H20C20.55 21 21 20.55 21 20S20.55 19 20 19H19V13H20M12 16C10.34 16 9 14.68 9 13.05C9 11.75 9.5 11.38 12 8.5C14.47 11.36 15 11.74 15 13.05C15 14.68 13.66 16 12 16Z",
  strokeOpacity: 1,
  strokeWeight: 0,
  fillOpacity: 1,
  scale: 1
};

const POINT_MARKER_SHIP_CONFIG = {
  path: "M6,6H18V9.96L12,8L6,9.96M3.94,19H4C5.6,19 7,18.12 8,17C9,18.12 10.4,19 12,19C13.6,19 15,18.12 16,17C17,18.12 18.4,19 20,19H20.05L21.95,12.31C22.03,12.06 22,11.78 21.89,11.54C21.76,11.3 21.55,11.12 21.29,11.04L20,10.62V6C20,4.89 19.1,4 18,4H15V1H9V4H6A2,2 0 0,0 4,6V10.62L2.71,11.04C2.45,11.12 2.24,11.3 2.11,11.54C2,11.78 1.97,12.06 2.05,12.31M20,21C18.61,21 17.22,20.53 16,19.67C13.56,21.38 10.44,21.38 8,19.67C6.78,20.53 5.39,21 4,21H2V23H4C5.37,23 6.74,22.65 8,22C10.5,23.3 13.5,23.3 16,22C17.26,22.65 18.62,23 20,23H22V21H20Z",
  strokeOpacity: 1,
  strokeWeight: 0,
  fillOpacity: 1,
  scale: 1
};

const POINT_MARKER_BULLDOZER_CONFIG = {
  path: "M4,4A1,1 0 0,0 3,5V10C2.54,10 2.14,10.31 2.03,10.76V13.97H2.29C2.65,13.37 3.3,13 4,13H13C13.7,13 14.35,13.37 14.71,13.97H16.03L16,11V11A1,1 0 0,0 15,10H13V8A1,1 0 0,0 12,7A1,1 0 0,0 11,8V10H9V5A1,1 0 0,0 8,4H4M5,6H7V10L7,11H5V6M17,11V19H22V18L19,17L18,11H17M4,15A2,2 0 0,0 2,17A2,2 0 0,0 4,19H13A2,2 0 0,0 15,17A2,2 0 0,0 13,15H4Z",
  strokeOpacity: 1,
  strokeWeight: 0,
  fillOpacity: 1,
  scale: 1
};

const LINE_SYMBOL_CONFIG = {
  path: "M 0,-2 0,2",
  strokeOpacity: 1,
  strokeWeight: 2,
  strokeColor: "rgb(24,25,26)",
  scale: 1
};

const LINE_PATH_CONFIG = {
  clickable: false,
  geodesic: false,
  strokeOpacity: 0,
  strokeColor: COLORS.LINE,
  icons: [
    {
      icon: LINE_SYMBOL_CONFIG,
      repeat: "10px"
    }
  ]
};

const POINT_START_ICON_CONFIG = {
  path: "M14.4,6L14,4H5V21H7V14H12.6L13,16H20V6H14.4Z",
  strokeOpacity: 0.7,
  strokeWeight: 4,
  // strokeColor: COLORS.POINT,
  // fillColor: COLORS.POINT_FILL,
  fillOpacity: 0.7,
  scale: 1
};

const POINT_FINISH_ICON_CONFIG = {
  path: "M14.4,6H20V16H13L12.6,14H7V21H5V4H14L14.4,6M14,14H16V12H18V10H16V8H14V10L13,8V6H11V8H9V6H7V8H9V10H7V12H9V10H11V12H13V10L14,12V14M11,10V8H13V10H11M14,10H16V12H14V10Z",
  strokeOpacity: 0.7,
  strokeWeight: 4,
  // strokeColor: COLORS.POINT,
  // fillColor: COLORS.POINT_FILL,
  fillOpacity: 0.7,
  scale: 1
};

//const BREADCRUMB_PATH_CONFIG = {
//  clickable: false,
//  geodesic: false,
//  strokeOpacity: 0,
//  strokeColor: COLORS.LINE,
//  icons: [
//    {
//      icon: LINE_SYMBOL_CONFIG,
//      repeat: "10px"
//    }
//  ]
//};

const mapSettings = {
  clickableIcons: false,
  streetViewControl: false,
  panControlOptions: false,
  gestureHandling: "cooperative",
  backgroundColor: COLORS.LANDSCAPE,
  mapTypeControl: true,
  mapTypeControlOptions: {
    mapTypeIds: ['roadmap', 'terrain', 'hybrid', 'satellite']
  },
  mapTypeId: 'roadmap',

  zoomControlOptions: {
    style: "SMALL"
  },
  zoom: 4,
  minZoom: 2,
  maxZoom: 18,
  // styles: [
  //   {
  //     featureType: "landscape",
  //     stylers: [
  //       { hue: COLORS.LANDSCAPE },
  //       { saturation: 50.2 },
  //       { lightness: -34.8 },
  //       { gamma: 1 }
  //     ]
  //   },
  //   {
  //     featureType: "poi",
  //     stylers: [{ visibility: "off" }]
  //   },
  //   {
  //     featureType: "road.highway",
  //     stylers: [
  //       { hue: COLORS.LANDSCAPE },
  //       { saturation: -19.8 },
  //       { lightness: -1.8 },
  //       { gamma: 1 }
  //     ]
  //   },
  //   {
  //     featureType: "road.arterial",
  //     stylers: [
  //       { hue: COLORS.LANDSCAPE },
  //       { saturation: 72.4 },
  //       { lightness: -32.6 },
  //       { gamma: 1 }
  //     ]
  //   },
  //   {
  //     featureType: "road.local",
  //     stylers: [{ visibility: "off" }]
  //   },
  //   {
  //     featureType: "transit",
  //     stylers: [{ visibility: "off" }]
  //   },
  //   {
  //     featureType: "administrative.province",
  //     stylers: [{ visibility: "off" }]
  //   },
  //   {
  //     featureType: "administrative.locality",
  //     stylers: [{ visibility: "off" }]
  //   },
  //   {
  //     featureType: "administrative.province",
  //     stylers: [{ visibility: "off" }]
  //   },
  //   {
  //     featureType: "administrative.land_parcel",
  //     stylers: [{ visibility: "off" }]
  //   },
  //   {
  //     featureType: "administrative.neighborhood",
  //     stylers: [{ visibility: "off" }]
  //   },
  //   {
  //     featureType: "administrative.country",
  //     elementType: "geometry.stroke",
  //     stylers: [{ visibility: "on" }, { color: COLORS.BORDERS }]
  //   },
  //   {
  //     featureType: "administrative",
  //     elementType: "labels",
  //     stylers: [{ visibility: "off" }]
  //   },
  //   {
  //     featureType: "water",
  //     stylers: [
  //       { hue: COLORS.WATER },
  //       { saturation: -63.2 },
  //       { lightness: 38 },
  //       { gamma: 1 }
  //     ]
  //   }
  // ]
};

export { mapSettings, LINE_PATH_CONFIG, POINT_MARKER_ICON_CONFIG, 
  POINT_MARKER_HEX_ICON_CONFIG, POINT_MARKER_TRIANGLE_ICON_CONFIG, 
  POINT_MARKER_SQUARE_ICON_CONFIG, POINT_MARKER_STAR_ICON_CONFIG, 
  POINT_START_ICON_CONFIG, POINT_FINISH_ICON_CONFIG, 
  POINT_MARKER_TRUCK_CONFIG, POINT_MARKER_TRAILER_CONFIG, 
  POINT_MARKER_FLASH_CONFIG, POINT_MARKER_BARREL_CONFIG, 
  POINT_MARKER_SHIP_CONFIG, POINT_MARKER_BULLDOZER_CONFIG
};
