<template>
  <div class="booleanformater">
    {{ booleanFormatted }}
  </div>
</template>

<script>
  export default {
  props: {
    //value: Boolean,
    value: String,
  },
  computed: {
    booleanFormatted () {
      //console.log("Boolean value:",this.value)
      let boolvalue = "False";
      let lowervalue = ""
      if(this.value === null || this.value === undefined || this.value === "") 
      {
        lowervalue = "" 
      } else {
        lowervalue = this.value.toString().toLowerCase();
      }
      
        
        switch(lowervalue)
        {
            case "":
            case "0":
            case "f":
            case "false":
            case "n":
            case "no":
            case "off":
            case "low":
                boolvalue = "False";
                break;
            case "1":
            case "t":
            case "true":
            case "y":
            case "yes":
            case "on":
            case "high":
                boolvalue = "True";
                break;
        }
        
        return boolvalue;
    },
  },  
}
</script>

<style>

</style>