<template>
  <div id="nav">
      <v-navigation-drawer
        v-model="drawer"
        :clipped="$vuetify.breakpoint.lgAndUp"
        :mini-variant="mini"
        app
        width="275px"
      >
        <template v-slot:prepend>
            <v-list-item two-line>
                <v-btn icon v-if="drawer" @click.stop="mini = !mini">
                  <v-icon v-if="!mini">mdi-menu-left</v-icon>
                  <v-icon v-else >mdi-menu-right</v-icon>
                </v-btn>
                <!-- <v-list-item-avatar>
                  <v-btn icon>
                    <v-icon>mdi-account-card-details-outline</v-icon>
                  </v-btn>
                </v-list-item-avatar> -->
              <v-list-item-content>
                <v-list-item-title class="customTitle">{{ sessionInfo.real_name }}</v-list-item-title>
                <v-list-item-subtitle>{{ sessionInfo.role_name }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-menu transition="slide-y-transition" bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on">
                      <v-icon>mdi-menu-down</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(item, i) in userMenu"
                      :key="i"
                        @click="goLink(item.link)"
                     >
                      <v-list-item-title>{{ item.text }}</v-list-item-title>
                      </v-list-item>
                      <v-list-item v-if="sessionInfo.sudoer" >
                        <v-switch color="#00677A" v-model="sessionInfo.su" @change="setSesssionInfo(sessionInfo.su)" hide-details label="Super User"></v-switch>
                      </v-list-item>
                  </v-list>
                </v-menu>
              </v-list-item-action>
            </v-list-item>
            <v-divider></v-divider>
        </template>

        <v-list dense>
          <template v-for="item in updatedPrivilegeTypes">
            <v-list-item
              :key="item.privilege_type_name"
              @click="addNav(item)"
            >
              <v-list-item-icon>
                <v-icon>{{ getIcon(item.privilege_type_name) }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title @click="addNav(item)">
                  {{ item.privilege_type_name }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>


        <!-- help settings etc -->
        <template v-slot:append>
          <v-divider></v-divider>
          <v-list-item>
              <v-list-item-action>
                <v-icon>mdi-help-circle</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Help</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
        </template>
      </v-navigation-drawer>

      <v-app-bar
        :clipped-left="$vuetify.breakpoint.lgAndUp"
        app
        color="#00677A"
        dark
        dense
      >
          <v-toolbar-title class="ml-0 pl-3">
            <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
              <span v-if="!sessionInfo.su" class="hidden-sm-and-down">{{ sessionInfo.org_name }}</span>
          </v-toolbar-title>
          <v-col cols="6" sm="6" md="3">
            <v-autocomplete
              v-if="sessionInfo.su"
              dense
              v-model="root_org_id"
              :items="enabledAccounts"
              color="#00677A"
              hide-no-data
              hide-selected
              item-text="name"
              item-value="id"
              label=""
              placeholder=""
              return-object
              @change="updateRootOrg()"
              filled
            ></v-autocomplete>
          </v-col>
          <v-spacer></v-spacer>
          <v-toolbar-title :key="countNotification">
            <v-btn icon @click="notificationDialog = true">
              <v-icon>mdi-bell-outline</v-icon>
           </v-btn>
           <span class="badge badge-light">{{getNotificationCount()}}</span>
          </v-toolbar-title>
          <v-toolbar-title v-if="sessionInfo.rebranded">
            <v-img :src="getImgUrl()" contain width="120" min-height="32" max-height="48"></v-img>
          </v-toolbar-title>
          <v-toolbar-title v-else>
            <v-img src="/images/logo_admin.png" contain width="120" min-height="32" max-height="48"></v-img>
          </v-toolbar-title>
      </v-app-bar>
      <slot></slot>
      <v-dialog
        v-model="notificationDialog"
        width="500"
        @input="inputChange"
        :key="dialogRefresh"
      >
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Notifications
             <v-spacer></v-spacer>
              <v-btn
              icon
              dark
              style="left:12px; color:black"
              @click="closeDialogNotification()"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
  
           <v-list three-line>
        <v-list-item-group
        >
        <div v-if="notifications.length > 0">
          <template v-for="(item) in notifications">
            <v-list-item :key="item.id" style="cursor:default;">
              <template v-slot:default="{ active }">
                <v-list-item-content>
                  <div style="display:inline-block">
                    <span style="float:right;font-size: 13px;color: #424242;">{{formatDate(item.start_at,'MM/DD/YYYY HH:mm')}}</span>
                  <span>{{ item.name }}</span>
                </div>
                  <v-list-item-subtitle v-if="!item.show" v-text="item.description.length > 100 ? item.description.substr(0,99)+' ...' : item.description.substr(0,99)" style="color:#424242;font-size: 15px;"></v-list-item-subtitle>
                  <span v-if="item.show" style="color:#424242;font-size: 15px;">{{item.description}}</span>
                  <a v-if="item.description.length > 100 && !item.show" @click="readMoreOrLess(item.id,true)" >more</a>
                  <a v-if="item.description.length > 100 && item.show" @click="readMoreOrLess(item.id,false)" >less</a>
                </v-list-item-content>
  
                <v-list-item-action>
                  <v-icon v-if="item.dismissible"
                    color="grey lighten-1"
                    @click="closeNotification(item.id)"
                  >
                    mdi-close
                  </v-icon>

                </v-list-item-action>
              </template>
            </v-list-item>

          </template>
        </div>
        <div v-else>
              <p><center>No notification found</center></p>
        </div>
        </v-list-item-group>
      </v-list>
        </v-card>
      </v-dialog>
  </div>
</template>

<script>
import axios from 'axios';
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);
dayjs.extend(timezone);
import { EventBus } from '../../event-bus';
export default {
  name: 'App',
  components: {

  },
  data: () => ({
    session_id: null,
    item: 0,
    dialog: false,
    drawer: null,
    mini: false,
    userMenu: [{text: "Settings", link:"/admin/myaccount/"}, {text: "Log Out",link:"/logout.php"}],
    privilegeTypes: [],
    clientInfo: null,
    appBarStyle: "blue darken-3",
    accounts: [],
    activeOrg: null,
    root_org_id: null,
    sessionInfo: {
        locale: "",
        logintype: "",
        appnames: {},
        username: "",
        user_id: "",
        real_name: "",
        su: false,
        org_id: "",
        org_name: "",
        org_abbr: "",
        rebranded: "",
        user_group_id: "",
        valid_org_ids: [],
        role_id: "",
        role_name: "",
        timezone_id: "",
        session_id: "",
      },
    notifications: [],
    notificationDialog:false,
    countNotification: 0,
    dialogRefresh:0,
    notificationsLists:[]
  }),
  methods: {
    readMoreOrLess(id,status){
//      console.log(id)
      let notifications =  this.notifications
      this.notifications = []
      notifications.forEach(item=>{
        if(item.id == id){
          item["show"] = status
        }
        this.notifications.push(item)
      })
    },
    inputChange(){
      document.getElementsByClassName('v-dialog--active')[0].scrollTop = 0;
      let notifications =  this.notifications
      this.notifications = []
      notifications.forEach(item=>{
        item["show"] = false
        this.notifications.push(item)
      })
      this.dialogRefresh++
    },
    closeDialogNotification(){
      document.getElementsByClassName('v-dialog--active')[0].scrollTop = 0;
      this.notificationDialog = false
      let notifications =  this.notifications
      this.notifications = []
      notifications.forEach(item=>{
        item["show"] = false
        this.notifications.push(item)
      })
      this.dialogRefresh++
    },
    getNotificationList(key){
      axios.post("/amfphp/json.php?contentType=application/json&session_id=" + this.session_id, { "serviceName": "NotificationsDelegate", "methodName": "getNotificationByOrgIdAndUserId", "parameters": Number(new Date(new Date().toUTCString()).getTime().toString().substr(0, 10)) })
      .then((response) => {
        //console.log(response)
        if(response.data.length > 0 && key){
          this.notifications = response.data
          this.notificationsLists = response.data
          this.notificationDialog = true
        }else{
          this.notifications = response.data
          this.notificationsLists = response.data
        }
      }).catch(
    )
    },
    closeNotification(id){
      if(id){
      let obj ={
        "dismissed_at":Number(new Date().getTime().toString().substr(0, 10)),
        "id":id
      }
      axios.post("/amfphp/json.php?contentType=application/json&session_id=" + this.session_id, {"serviceName":"NotificationsDelegate", "methodName":"closeNotification", "parameters":obj})
      .then((response) => {
        console.log(response)
        let notifications = this.notifications
        this.notifications = []
        notifications.forEach(a=>{
          if(a.id != id){
            this.notifications.push(a)
          }
        })
        if (this.notifications.length == 0) {
          this.notificationDialog = false
        }
        this.countNotification++
      })
      .catch()
    }
    },
    getNotificationCount(){
      let count = this.notifications.length > 0 && this.notifications.length > 10 ? 10 + "+" : this.notifications.length ;
     //console.log(count)
      return count;
    },
    formatDate(d,format) {
      let formattedDate = dayjs.unix(d)
      formattedDate.tz(this.sessionInfo.timezone_id)
      let zone = formattedDate.toDate().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2]
      return formattedDate.format(format) + " " + zone
    },
    getImgUrl() {
      return '/images/custom_logos/' + this.sessionInfo.org_abbr + '_admin.png'
    },
    updateRootOrg() {
      axios.post("/amfphp/json.php?contentType=application/json&session_id=" + this.session_id, {"serviceName":"SessionDelegate", "methodName":"setRootOrg",
        "parameters":{"root_org_id": this.root_org_id.id}})
      .then((response) => {
        console.log("response",response)
        this.sessionInfo.org_id = this.root_org_id.id
        setTimeout(function reload() {
          location.reload();
        }, 100);

      })
      //console.log("sessionInfo",this.sessionInfo)
    },
    checkSession(){
      axios.get("/ajax/checkSession.php?session_id=" + this.session_id)
        .then((response) => {
          if(!response.data.session) {
            window.location = "/logout.php"
          }
        })
    },
    getIcon(name) {
      let icon = "mdi-circle"
      switch (name) {
        case 'Device Management':
          icon =  "mdi-sim"
        break
        case 'Account Management':
          icon =  "mdi-account-group"
        break
        case 'Asset Management':
          icon =  "mdi-truck"
        break
        case 'Battery Management':
          icon = "mdi-battery-medium"
        break
        case 'Dashboard':
          icon =  "mdi-gauge"
        break
        case 'Downloads':
          icon =  "mdi-file-download-outline"
        break
        case 'Datastream Management':
          icon =  "mdi-cloud-upload-outline"
        break
        case 'GPS Devices':
          icon =  "mdi-devices"
        break
        case 'GPS Locate':
          icon =  "mdi-crosshairs-gps"
        break
        case 'Map':
          icon =  "mdi-map-outline"
        break
        case 'Meter Management':
          icon =  "mdi-counter"
        break
        case 'Inventory Management':
          icon =  "mdi-file-cabinet"
        break
        case 'Inventory Assignment':
          icon =  "mdi-list-box-outline"
        break
        case 'Proximity Reports':
        case 'Proximity Report':
          icon =  "mdi-radar"
        break
        case 'Reports':
          icon =  "mdi-script-text-outline"
        break
        case 'User Management':
          icon =  "mdi-account-box-multiple-outline"
        break
        case 'Role Management':
          icon =  "mdi-account-cog-outline"
        break
        case 'System':
          icon =  "mdi-monitor-dashboard"
        break
        case 'Transactions':
          icon =  "mdi-book-information-variant"
        break
        case 'Notification Management':
          icon =  "mdi-message-badge-outline"
        break
        default:
          icon =  "mdi-circle"
      }
      return icon
    },
    goLink(link) {
      window.location = link
    },
    goTo(path) {
      console.log("/admin/" + path + "/")
    },
    addNav(item) {
      window.location = "/admin/"  + item.directory + "/"
    },
    setSesssionInfo(value) {
      //console.log("value", value)
      if(value){
        axios.post("/amfphp/json.php?contentType=application/json&session_id=" + this.session_id, {"serviceName":"SessionDelegate", "methodName":"setSuperUser","parameters":"true"})
        .then((response) => {
          this.sessionInfo = response.data
          this.$forceUpdate()
          setTimeout(function reload() {
            location.reload();
          }, 100);

        })
      } else {
        axios.post("/amfphp/json.php?contentType=application/json&session_id=" + this.session_id, {"serviceName":"SessionDelegate", "methodName":"setSuperUser","parameters":"false"})
        .then((response) => {
          this.sessionInfo = response.data
          this.$forceUpdate()
          setTimeout(function reload() {
            location.reload();
          }, 100);

        })
      }
    },
  },
  computed: {
    enabledAccounts() {
        if(this.accounts == null || this.accounts == undefined) return []
        if(this.sessionInfo === null || this.sessionInfo === undefined) return this.accounts
        let allAccounts = []
        for(let a = 0; a < this.accounts.length; a++)
        {
          delete Object.assign(this.accounts[a], {isDisabled: this.accounts[a].disabled})['disabled']
          allAccounts.push(this.accounts[a])
        }
        return allAccounts
    },
    isFelix() {
      if (this.sessionInfo) {
        if (this.sessionInfo.appnames) {
          if (this.sessionInfo.appnames.FELIX) {
            return this.sessionInfo.appnames.FELIX
          } else {
            return undefined
          }
        } else {
          return undefined
        }
      } else {
        return undefined
      }
    },
    updatedPrivilegeTypes() {
      let types = []
      for(var i = 0; i < this.privilegeTypes.length; i++)
      {
        if(this.privilegeTypes[i].privilege_type_name === "My Account" ) {
          //skip my account
        } else {
          types.push(this.privilegeTypes[i])
        }
      }
      return types
    }
  },
  mounted() {
    this.session_id = document.querySelector('#sessionid').getAttribute("data-sessionid");
    axios.post("/amfphp/json.php?contentType=application/json&session_id=" + this.session_id, {"serviceName":"SessionDelegate", "methodName":"getPrivilegeTypes","parameters":""})
      .then((response) => {
        this.privilegeTypes = response.data
      });
     axios.post("/amfphp/json.php?contentType=application/json&session_id=" + this.session_id, {"serviceName":"LegacyAccountService", "methodName":"getClientInfo","parameters":""})
      .then((response) => {
        this.clientInfo = response.data
      });
      axios.post("/amfphp/json.php?contentType=application/json&session_id=" + this.session_id, {"serviceName":"OrgDelegate", "methodName":"getParentFreeList","parameters":""})
      .then((response) => {
        this.accounts = response.data
      })
      .catch(error => console.log(error))

      axios.post("/amfphp/json.php?contentType=application/json&session_id=" + this.session_id, {"serviceName":"SessionDelegate", "methodName":"getSessionInfo", "parameters":""})
      .then((response) => {
        this.sessionInfo = response.data
        this.root_org_id = this.sessionInfo.org_id
      })
      .catch(error => console.log(error))
      axios.post("/amfphp/json.php?contentType=application/json&session_id=" + this.session_id, { "serviceName": "NotificationsDelegate", "methodName": "getNotificationByOrgIdAndUserId", "parameters": Number(new Date(new Date().toUTCString()).getTime().toString().substr(0, 10)) })
      .then((response) => {
        //console.log(response)
        if(response.data.length > 0){
          this.notifications = response.data
          this.notificationsLists = response.data
        }
      }).catch(
    )
    if(!sessionStorage.getItem("nStatus")){
      //console.log("Session called")
      this.getNotificationList(true)
      sessionStorage.setItem("nStatus",1)
    }
  },
  created() {
    this.interval = setInterval(() => this.checkSession(), 60000);
    EventBus.$on('NotificationStatusSet', ()=>{
      //console.log("called emit ")
      this.getNotificationList(false)
    });
  },
};
</script>

<style scoped>
.maplink {
  list-group-items-item-padding: 30px;
  list-padding: 0px 6px;
}
.badge {
  position: relative;
  top: -12px;
  left: -19px;
  font-size:12px
}

</style>
