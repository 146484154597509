<template>
  <div class="users">
    <v-form ref="user" v-model="isValid" lazy-validation>
      <v-card-text>
        <v-row dense no-gutters>
          <v-col>
            <v-text-field
              color="#00677A"
              dense
              label="User Name"
              v-model="user.userName"
              required
              @input="user.error = ''"
              :rules="usernameRules"
              :error-messages="user.error"
              :loading="loadingAbbr"
              :disabled="loadingAbbr"
              @change="checkUsername"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense no-gutters>
          <v-col>
            <v-text-field
              color="#00677A"
              dense
              label="Real Name"
              v-model="user.realName"
              required
              @change='checkEmpty($event, "realName")'
              :rules="realnameRules"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense no-gutters>
          <v-col>
            <v-switch color="#00677A" style="margin-top: 0px !important;" dense v-if="user.id != null" @change="clearPasswordFields()" v-model="user.setPassword"  label="Update Password" required />
            <v-text-field
              color="#00677A"
              dense
              label="Password"
              v-model="user.password"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword ? 'text' : 'password'"
              :rules="!user.setPassword? [] : [passwordRules.required, passwordRules.min, passwordRules.upper, passwordRules.number, passwordRules.special]"
              hint="At least 8 characters"
              @click:append="showPassword = !showPassword"
              counter
              require
              hide-details="auto"
              :disabled="!user.setPassword"
            ></v-text-field>
              <v-text-field
              color="#00677A"
              class="mb-2"
              label="Confirm Password"
              v-model="confirmPassword"
              :append-icon="showConfirm ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="!user.setPassword? [] : [passwordRules.required, passwordRules.min, passwordConfirmationRule]"
              hint="Needs to match password"
              @click:append="showConfirm = !showConfirm"
              :type="showConfirm ? 'text' : 'password'"
              required
              hide-details="auto"
              :disabled="!user.setPassword"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense no-gutters v-if="page != 'myaccount'">
          <v-col>
            <v-select
              color="#00677A"
              dense
              :items="availableroles"
              label="Role"
              v-model="user.roleId"
              item-text="name"
              item-value="id"
              value="user.role"
              :rules="[v => !!v || 'Role is required']"
              required
            ></v-select>
          </v-col>
        </v-row>
        <v-row dense no-gutters v-if="page != 'myaccount'">
          <v-col>
            <v-select
              color="#00677A"
              dense
              :items="availablegroups"
              item-text="fullPath"
              item-value="id"
              v-model="user.groupId"
              value="user.groupId"
              label="Group"
              :rules="[v => !!v || 'Group is required']"
              required
            ></v-select>
          </v-col>
        </v-row>
        <v-row dense no-gutters v-show="sessionInfo.su">
          <v-col>
            <v-switch color="#00677A" style="margin-top: 0px !important;" dense v-model="user.sudoer" label="Super User" />
          </v-col>
        </v-row>
        <v-row dense no-gutters>
          <v-col>
            <v-text-field
              color="#00677A"
              dense
              label="E-Mail"
              v-model="user.email1"
              required
              @change='checkEmpty($event, "email1")'
              :rules="emailRules"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense no-gutters>
          <v-col>
            <v-text-field
              color="#00677A"
              dense
              label="Phone"
              v-model="user.phone1"
              :rules="[v => !!v || 'Phone is required']"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense no-gutters>
          <v-col>
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              :nudge-width="200"
              dense
              absolute
              offset-x
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  color="#00677A"
                  dense
                  v-model="user.timezoneId"
                  label="TimeZone"
                  append-icon="mdi-globe-model"
                  readonly
                  :rules="[v => !!v || 'Timezone is required']"
                  v-on="on"
                ></v-text-field>
              </template>
              <TimeZoneSelector class="ml-32" @selectedTimeZoneId="updateTimezone" :timezone="user.timezoneId" :timezones="timezones"/>
            </v-menu>
          </v-col>
        </v-row>
        <v-row dense no-gutters>
          <v-col>
            <v-switch color="#00677A" style="margin-top: 0px !important;" dense v-model="user.useMetric"  label="Use Metric System" required />
          </v-col>
        </v-row>
        <v-row dense no-gutters v-if="user.id != sessionInfo.user_id">
          <v-col>
            <v-switch color="#00677A" style="margin-top: 0px !important;" dense v-model="user.disabled" label="Disable Log-In" required />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="$emit('cancelUser')">Cancel</v-btn>
         <template v-if="page != 'myaccount' && !sessionInfo.su && user.id != sessionInfo.user_id">
          <v-btn v-if="user.sudoer != 1" class="tabOrToolBarColor" :disabled="!isValid" @click="saveUser">Save</v-btn>
          <v-btn v-else class="tabOrToolBarColor" :disabled="true">Save</v-btn>
        </template>
        <template v-else>
          <v-btn class="tabOrToolBarColor" :disabled="!isValid" @click="saveUser">Save</v-btn>
        </template>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-form>

  </div>
</template>

<script>
  import axios from 'axios';
  import TimeZoneSelector from '../util/TimeZoneSelector.vue';

  export default {
  data: () => ({
    session_id: null,
    showPassword: false,
    showConfirm: false,
    isValid: true,
    errors: [],
    menu: false,
    loadingUsername: false,
    confirmPassword: "",
    page: null,
    // emailFormat: [{label:"HTML", value:'html'},{label:"Text", value:"test"}],
    rules: {
      required: value => !!value || 'Required.',
    },
    usernameRules: [
        v => !!v || 'Name is required',
        v => (v && v.length >= 2) || 'Name must be geater than 2 characters',
      ],
    realnameRules: [
        v => !!v || 'Name is required',
        v => (v && v.length >= 2) || 'Name must be greater than 2 characters',
      ],
    passwordRules: {
        required: value => !!value || "Required.",
        min: v => v.length >= 8 || "Min 8 characters",
        upper: v => /(?=.*[A-Z])/.test(v) || 'Must have one uppercase character',
        number: v => /(?=.*\d)/.test(v) || 'Must have one number',
        special: v => /([!@$%])/.test(v) || 'Must have one special character [!@$%]'
    },
    emailRules: [
      v => !!v || 'Email is required',
      v => /.+@.+/.test(v) || 'E-mail must be valid'
    ],
    phoneRules: [
      v => !!v || 'Phone is required',
      v => /^[+]?(1-|1\s|1|\d{3}-|\d{3}\s|)?((\(\d{3}\))|\d{3})(-|\s)?(\d{3})(-|\s)?(\d{4})$/g.test(v) || 'Must be valid phone number',
    ],

  }),
  components: {
    TimeZoneSelector,
  },
  props: {
    sessionInfo: Object,
    user: Object,
    roles: Array,
    usergroups: Array,
    viewonly: Boolean,
    timezones: Array,
  },
  methods: {
    clearPasswordFields() {
      this.user.password = ""
      this.user.confirmpassword = ""
    },
    requiredNew() {
      return this.user.id != null && this.user.password === "" && this.user.confirmpassword === "" ||  "Required."
    },
    updateTimezone(id) {
      this.user.timezoneId = id
      this.menu = false

    },
    checkUsername() {
      this.loadingUsername = true;
      axios.post("/amfphp/json.php?contentType=application/json&session_id=" + this.session_id,{"serviceName":"UserDelegate","methodName":"checkUsername","parameters":{"name":this.user.userName}})
        .then((response) => {
          if(response.data) {
            this.user.error = "Username in use, please select another.";
          } else {
            this.user.error = "";
          }
      })
      this.loadingUsername = false;
    },
    minimum (value) {
      if (value === undefined || value === null) {
        return false
      } else {
        if (value.length >= 8 )
        {
          return true
        } else {
          return "Min 8 characters"
        }
      }
    },
    duplicateUsername (value) {
      if (value === undefined || value === null) {
        return false
      } else {
        if (value.length >= 8 )
        {
          return true
        } else {
          return "Min 8 characters"
        }
      }
    },
    saveUser() {
      if(this.$refs.user.validate()){
        this.user.confirmpassword = this.confirmPassword
        this.$emit('saveUser',this.user)
      }
    },
    checkEmpty(value, field){
      if(!value) {
        return field + ' is required.'
      }
    },
  },
  computed: {
    passwordConfirmationRule() {
      return this.confirmPassword === this.user.password  ||  "Password must match"
    },
    availableroles() {
      if (!this.roles) return undefined
      if(this.roles.length > 0) {
        return this.roles.slice(0);
      } else {
        return [];
      }
    },
    availablegroups() {
      if (!this.usergroups) return undefined
      if(this.usergroups.length > 0) {
        return this.usergroups.slice(0);
      } else {
        return [];
      }
    },
  },
  mounted() {
    this.confirmPassword = ""
    this.session_id = document.querySelector('#sessionid').getAttribute("data-sessionid");
    let split = window.location.pathname.split('/')
    this.page = split[2]
  }
}
</script>

<style>
  .v-messages__message {
    padding: {
      bottom: 5px;
    }
  }
</style>
