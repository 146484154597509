<template>
  <div class="google-map" ref="googleMap" style="height: 70vh !important;">
    <template v-if="Boolean(this.google) && Boolean(this.map)">
      <slot
        :google="google"
        :$map="$map"
      />
    </template>
  </div>
</template>

<script>

import GoogleMapsApiLoader from "../../plugins/google-maps-api-loader";

export default {
  data() {
    return {
      google: null,
      $map: null,
      apiKey:  "",
      session_id: null,
      smallMapSettings:
      {
        clickableIcons: false,
        streetViewControl: false,
        panControlOptions: false,
        gestureHandling: "cooperative",
        mapTypeControl: false,
        zoomControlOptions: {
          style: "SMALL"
        },
        zoom: 4,
        minZoom: 2,
        maxZoom: 18,
      },
      marker: false,
      geofence: null
    };
  },
  props: {
    mapCenter: Object,
    markers: Array,
    readOnly: Boolean,
    refresh: Boolean,
    zoomOnLoad: Boolean,
  },
  async mounted() {
    const googleMapApi = await GoogleMapsApiLoader({
      apiKey: this.apiKey
    });
    this.google = googleMapApi;
    this.initializeMap();
    this.session_id = document.querySelector('#sessionid').getAttribute("data-sessionid");

    this.google.maps.event.trigger(this.$map, 'resize');
  },
  methods: {
    initializeMap() {
      const that = this
      const mapContainer = this.$refs.googleMap;

      this.$map = new this.google.maps.Map(mapContainer, this.mapConfig);

      if(this.markers[0] === null || this.markers[0] === undefined) {
        // do nothing no markers supplied
        this.$map.setZoom(9);
        this.$map.setCenter({lat: 33.96328, lng: -84.19405 });
      } else {
        if(this.zoomOnLoad) {
          this.marker = new that.google.maps.Marker({
            position: this.markers[0],
            map: that.$map,
            draggable: true
          });
          this.$map.setZoom(9);
          this.$map.setCenter(this.markers[0]);
          this.markerLocation(this.markers[0].lat, this.markers[0].lng)
        } else {
          this.marker = new that.google.maps.Marker({
            position: this.markers[0],
            map: that.$map,
            draggable: true
          });
        }

        this.google.maps.event.addListener(this.marker, 'dragend', function(){
          that.markerLocation(that.marker.getPosition().lat(), that.marker.getPosition().lng());
        });
      }
      if(!this.readOnly) {
        this.google.maps.event.addListener(this.$map, 'click', function(event) {
          var clickedLocation = event.latLng;
          if(that.marker === false){
              that.marker = new that.google.maps.Marker({
                  position: clickedLocation,
                  map: that.$map,
                  draggable: true //make it draggable
              });

              that.google.maps.event.addListener(that.marker, 'dragend', function() {
                  that.markerLocation(that.marker.getPosition().lat(), that.marker.getPosition().lng());
              });
          } else{
              that.marker.setPosition(clickedLocation);
          }
          that.markerLocation(that.marker.getPosition().lat(),that.marker.getPosition().lng());
        });
      }
      this.$emit('mapLoaded', true)
    },
    markerLocation(latitude,longitude){
      this.$emit('position',{lat: latitude, lng: longitude})
    },
    drawGeofence(lat, lon, radius) {
      if(this.geofence) {
        this.geofence.setMap(null);
      }
      this.geofence = new this.google.maps.Circle({
                radius: Number(radius) * 1609.344, // miles to meters
                center:{
                  lat: lat,
                  lng: lon},
                fillColor: "#0093D9",
                fillOpacity: 0.35,
                strokeWeight: 2,
                strokeColor: '#0093D9',
                strokeOpacity: 0.8,
                map: this.$map
              });
      let bounds = new this.google.maps.LatLngBounds();
      bounds.union(this.geofence.getBounds());
      this.$map.fitBounds(bounds);
    },
    resetMap() {
      let that = this
      if(this.marker) {
        this.marker.setMap(null);
        this.marker = false;
      }
      if(this.geofence) {
        this.geofence.setMap(null);
        this.geofence = null;
      }
      setTimeout(function() {
        that.$map.setZoom(9);
        that.$map.setCenter({lat: 33.96328, lng: -84.19405});
      }, 500);
    },
    updateMap(lat, lon, radius) {
      let that = this
      let l1 = parseFloat(lat)
      let l2 = parseFloat(lon)
      if(this.marker) {
        this.marker.setPosition({lat: l1, lng: l2});
      } else {
        this.marker = new that.google.maps.Marker({
          position: {lat: l1, lng: l2},
          map: that.$map,
          draggable: true
        });
        this.google.maps.event.addListener(this.marker, 'dragend', function(){
          that.markerLocation(that.marker.getPosition().lat(), that.marker.getPosition().lng());
        });
      }
      if(this.geofence) {
         this.geofence.setMap(null);
      }
      if(radius > 0) {
        setTimeout(function() {
          that.drawGeofence(l1, l2, radius);
        }, 500);
      } else {
        this.$map.setZoom(9);
        this.$map.setCenter({lat: l1, lng: l2});
      }
    }
  },
  computed: {
    mapConfig() {
      return {
        ...this.smallMapSettings,
        center: this.mapCenter
      };
    },
  },
  watch: {
    refresh: function () {
      this.initializeMap()
      this.$emit('maprefreshed',false)
    },
  }
};
</script>

<style scoped>
.google-map {
  height: 70vh;
  position: relative;
  overflow: hidden;
};
</style>
